import React, { useState, useEffect } from "react"
import Web3 from "web3"
import { PageProps } from "gatsby"

import Terms from "../../components/pages/terms"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Noobs from '../../../abis/mainnet/Noobs.json' // TODO: cameron look at better method to get abi (see old code)
import DiscordButton from '../../components/buttons/DiscordButton'


const SuccessPage: React.FC<PageProps> = pageProps => {
  // Pull web3 information - might be worth pulling this out so abstracted further from the page.
  // A HOC or putting it in Layout might be helpful.
  if (typeof window === 'undefined') { // FIXME: Messy hack that basically removes SSR but the site builds...
    return null
  }
  const [account, setAccount] = useState<string | null>(null)
  const [contract, setContract] = useState<any | null>(null)

  // web3
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")

  const getAndSetAccount = async () => {
    // Get customer account (wallet address) from meta mask
    const newAccounts = await web3.eth.getAccounts()
    const newAccount = newAccounts[0] as string
    setAccount(newAccount)
  }

  useEffect(() => {
    const setupWeb3Info = async () => {
      // Get contract from the network
      const networkId = await web3.eth.net.getId()
      const networkData = Noobs.networks[networkId]
      if (networkData) {
        const abi = Noobs.abi
        const contractAddress = networkData.address
        const contract = new web3.eth.Contract(abi, contractAddress)
        setContract(contract)
      }

      getAndSetAccount();
    }
    // Update the document title using the browser API
    setupWeb3Info()
  }, []);

  return (
    <Layout pageProps={pageProps} accountAddress={account} contract={contract} getAndSetAccount={getAndSetAccount}>
      <SEO title="Terms & Conditions" />
      <Terms />
      <DiscordButton/>
    </Layout>
  )
}

export default SuccessPage
